<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner"  :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerDrop">
              <a>
                <v-btn text class="nocap px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.ALL_OWNER }}
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.FUNCTIONS }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
               <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="addFunInfoPop = true"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                {{ labels.ADD_PLAT_FUN }}
              </v-btn> -->
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between">
        <div> 
          <v-chip outlined
            @click="makeSelectedEmpty"
            class="ma-2 white"
          v-if="selectedFun.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedFun.length }} selected
          </v-chip>
        </div>
          <div>
             <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="addFunInfoPop = true; isValidForm=true;"
              >
                <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-network-outline </v-icon> -->
                   <v-icon class="pr-1 add" style="font-size:26px">mdi-checkbox-multiple-marked-circle-outline </v-icon>
                Add Platform Function
              </v-btn>
            <v-btn text
              @click.stop="deletFunction = true"
              :disabled="!selectedFun.length"
              class="error--text nocap py-5"
            >
              <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
              {{ labels.DELETE }}
            </v-btn>
          </div>
        </div>
          <div>
            <v-data-table
              :mobile-breakpoint="0"
              v-model="selectedFun"
              :headers="headers"
              :items="funListing"
              item-key="function_id"
              show-select
              @click:row="editFunction"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addFunInfoPop"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.ADD_PLAT_FUN }}</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <addFunInfo :newItems="newItems" :isValidForm="isValidForm" :labels="labels"/>
                    <div class="px-md-10">
                      <v-alert
                      text
                      :type="response_status"
                      v-if="response_msg.length"
                    >
                      {{ response_msg }}
                    </v-alert>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearObj()"
                    >
                      {{ labels.ADD_CANCEL }}
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="save"
                    >
                      {{ labels.ADD_CREATE }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successFun"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{response_message}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successFun = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletFunction"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected function?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletFunction = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deleteFunction()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ Function Detail Pages +++++--------->
          <v-dialog v-model="funDetailDialog" width="1000" scrollable>
            <funDetail
              @closeDetailDialog="closeDialog"
              :rowItem="selectedRowData"
              @closeFunctionDetail="closeFunctionDetail"
              :labels="labels"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit function +++++--------->
          <v-dialog
            v-model="editCloseDialog"
            width="1000"
            scrollable
            class="d-none"
            persistent
          >
            <editFun :newItems="selectedRowData" @closeFunEdit="closeEdit" :labels="labels" :response_status="response_status" :response_msg="response_msg" @saveFunEditedData="saveFunEdit"/>
          </v-dialog>
          <!-- ----------------------------- -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
// import navigation from "./navigation.vue";
// import msDetailPage from "./msDetailPage.vue";
import loaderimg from "./loaderimg.vue";
import editFun from "./funEdit.vue";
import addFunInfo from "./funPopUp/addFunInfo.vue";
import funDetail from "./funDetail.vue";
import API_Util from "../services/util/API_Util.js";
import headline from "./headline.vue";

export default {
  components: {
    editFun,
    addFunInfo,
    funDetail,
    loaderimg,
    headline
  },
  name: "platformFunction",
  data: () => ({
    response_message: "",
    isValidForm: true,
    editCloseDialog: false,
    successFun: false,
    addFunInfoPop: false,
    funDetailDialog: false,
    selectedRowData: {},
    editFun: false,
    currentStep: 1,
    deletFunction: false,
    enableDelete: false,
    insertInfoMessage: false,
    insertInfoMessageSucess: false,
    dialog: false,
    password: "Password",
    selectedFun: [],
    ownerName: "",
    funListing: [],
    user: "",
    newItems: {
        access: "",
        apiResid: "",
        apiendpoint: "",
        clas: "CLASS_INTERNAL",
        code: "",
        connType: "",
        consistsfunctionIds: [],
        dataCollectMethod: null,
        dataSchema: [],
        dateUpdated: "",
        deleteLimitationIds: [],
        deltConsistsIds: [],
        deltTagIds: [],
        deployType: "",
        description1: "",
        description2: "",
        docUrl: "",
        feature: [],
        fnType: "FUN_ATOMISTIC",
        iconUrl: "",
        id: 0,
        images: [],
        keywords: "",
        limitFields: [],
        logoUrl: "",
        mandatoryFields: "",
        microserviceProducer: "",
        modelExport: null,
        name: "",
        ownerId: localStorage.getItem("owner"),
        pricing: [],
        source: "",
        tags: [],
        termUrl: "",
        type: 140,
        updatedBy: 2,
        urlCode: "",
        useCase: "",
        webLink: "",
        requestTypes: [],
        deleteRequestTypes: [],
      },
    response_msg: "",
    response_status: "",

    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    apiKey: "",
    owner: "",
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Functions",
        disabled: true,
        href: "",
      },
    ],
  selectedOwner: "",
  ownerList: [],
  labels: {},
  lang: 'EN',
  loader: true,
  ownerType: "",
  ownerDrop: "",
  ownerDesc: "",
  }),

  computed: {
    allSelectedInt() {
      return this.selectedInt.length === this.items.length;
    },
    allSelectedMs() {
      return this.selectedMs.length === this.msSelect.length;
    },
    categoriesInternal() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    categoriesMS() {
      const search = this.search.toLowerCase();

      if (!search) return this.msSelect;

      return this.msSelect.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selectionsInt() {
      const selections = [];

      for (const selection of this.selectedInt) {
        selections.push(selection);
      }

      return selections;
    },

    selectionsMs() {
      const selections = [];

      for (const selection of this.selectedMs) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selectedInt() {
      this.search = "";
    },
    selectedMs() {
      this.search = "";
    },
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  methods: {
    clearObj(){
      this.addFunInfoPop = false;
      this.refreshData();
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('ownerType', obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getFuncList();
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload()});
    },
    closeFunctionDetail(value) {
      this.funDetailDialog = value;
    },
    clearObjectData() {
      this.addFunInfoPop = false;
      this.response_msg = "";
    },
    closeDialog() {
      this.funDetailDialog = false;
      this.editCloseDialog = true;
    },
    openFun(type) {
      this.$router.push({ name: "popup", params: { type: type } });
    },
    nextStep() {
      if (this.currentStep < 7) {
        this.currentStep = this.currentStep + 1;
      }
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },

    editFunction(func_id) {
      this.funDetailDialog = true;
      var details = {"fId": func_id.function_id}
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.functionUrl, details, {
          headers,
        })
        .then((response) => {
          this.selectedRowData = response.data;
          console.log(this.selectedRowData)
        });
    },
    refreshData() {
      this.newItems.name = "";
      this.newItems.description1 = "";
      this.newItems.code = "";
    },
    saveFunEdit(emittedData) {
      this.newItems = emittedData;
      this.save();
    },
    save() {
      if (this.newItems.name == "") {
          this.isValidForm = false;
      } else if (this.newItems.code == "") {
          this.isValidForm = false;
      } else if (this.newItems['description1'] == undefined || this.newItems.description1 == "") {
        this.isValidForm = false;
      } else {
        this.isValidForm = true;
        this.newItems.urlCode = this.newItems.code;
        if (!this.newItems.micro_type) {
          this.newItems.micro_type = 'OWN';
        }
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "x-api-key": this.apiKey,
      };
      this.newItems.owner_id = localStorage.getItem('owner');
      this.newItems.updatedBy = this.user;
      var self = this;
      API_Util.URLS.axios
        .post(API_Util.URLS.addFunction, this.newItems, { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.message === "NAME_EXIST") {
            self.response_msg = "Function Title or Code Already Exists";
            self.response_status = "error";
            setTimeout(() => {
              self.response_msg = "";
            }, 2000);
          } else {
            if(self.newItems.id > 0){
              self.response_message = "Successfully Updated";
              self.successFun = true;
            }
            else{
              self.response_message = "Successfully Created";
              self.successFun = true;
            }
            setTimeout(() => {
              self.clearFunctionData();
              self.addFunInfoPop = false;
              self.editCloseDialog = false;
              self.clearObjectData();
              self.getFuncList();
            }, 3000)
          }
        })
        .catch((err) => console.log(err));
      }
    },
    closeEdit(status) {
      this.editCloseDialog = status;
    },
    closeDetail(status) {
      this.funDetailDialog = status;
    },
    deleteFunction() {
      const headers = { "x-api-key": this.apiKey };
      this.selectedFun.forEach(function (arrayItem) {
        const data={
          "fId": arrayItem.function_id,
          "owner_id":localStorage.getItem("owner")
        }
        API_Util.URLS.axios
          .post(API_Util.URLS.deleteFunction ,data, {
            headers,
          })
          .then((response) => response)
          .catch((err) => console.log(err));
      });
      this.selectedFun = [];
      // obj.value = false;
      this.deletFunction = false;
      this.getFuncList();
      setTimeout(() => {
        this.getFuncList();
      },100);
    },
    makeSelectedEmpty() {
      this.selectedFun = [];
    },
    getFuncList() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      var data={owner_id:localStorage.getItem("owner"),type:'functions'}
      API_Util.URLS.axios
        .post(API_Util.URLS.functionList ,data, { headers })
        .then((response) => {
          this.funListing = response.data.results;
          this.loader = false;
        });
    },
    clearFunctionData(){
      this.newItems = {
        access: "",
        apiResid: "",
        apiendpoint: "",
        clas: "CLASS_INTERNAL",
        code: "",
        connType: "",
        consistsfunctionIds: [],
        dataCollectMethod: null,
        dataSchema: [],
        dateUpdated: "",
        deleteLimitationIds: [],
        deltConsistsIds: [],
        deltTagIds: [],
        deployType: "",
        description1: "",
        description2: "",
        docUrl: "",
        feature: [],
        fnType: "FUN_ATOMISTIC",
        iconUrl: "",
        id: 0,
        images: [],
        keywords: "",
        limitFields: [],
        logoUrl: "",
        mandatoryFields: "",
        microserviceProducer: "",
        modelExport: null,
        name: "",
        ownerId: localStorage.getItem("owner"),
        pricing: [],
        source: "",
        tags: [],
        termUrl: "",
        type: 140,
        updatedBy: 2,
        urlCode: "",
        useCase: "",
        webLink: "",
        requestTypes: [],
        deleteRequestTypes: [],
      }
    },
    getFlexi(){
      API_Util.URLS.axios
      .post(API_Util.URLS.getlblbyscr,
      {
        owner_id : this.owner,
        scrId : "FUN_SET",
        lang : this.lang
      },
      { headers: { "x-api-key" : this.apiKey } }
      )
      .then((response) => {
        this.labels = response.data.labels;
        this.headers = [
          {
        text: this.labels.AD_NAME,
        align: "start",
        sortable: true,
        value: "function_name",
      },
      { text: this.labels.CODE, value: "code" },
      { text: this.labels.FUN_TYPE, value: "type" },
      { text: this.labels.DESC, value: "function_description" },
      { text: this.labels.CREATEBY, value: "created_by" },
      { text: this.labels.CREATEDATE, value: "date_created" },
        ];
      })
    },
  },

  created() {
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.user = sessionObj.session.id;
    this.ownerType = localStorage.getItem("ownerType");
    this.selectedOwner = localStorage.getItem("ownerName");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getOrgList();
    this.getFuncList();
    this.getFlexi();
    this.clearFunctionData();
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}

</style>

<template>
    <div class="s-css-reset" style="width:100%">
        <!-- Component of activated users operation buttons group -->
        <div v-show="!showDeletedUsers">
            <v-row class="d-flex justify-space-between">
                <!-- Add users -->
                <v-btn
                    v-if="uAccess.ORG_UPDATEUSERINFO"
                    plain
                    elevation="0"
                    color="var(--v-tertiary2-base)"
                    text
                    class="nocap"
                    @click="showAddUser=true;toggleDialog('orgManageUsersAddUser');reload()"
                >
                    <v-icon>
                        mdi-account-plus
                    </v-icon>
                    {{labels.ADD_USR_BTN}}
                </v-btn>

                <!-- Reset Password -->
                <!-- Will be enable if there is at least 1 item been selected in table -->
                <v-btn
                    v-if="onprem!='YES'"
                    plain
                    elevation="0"
                    text
                    class="nocap"
                    color="tertiary1"
                    :disabled="!selectedUsers[0]"
                    @click="toggleConfirm('resetUsersPassword')"
                >
                    <v-icon>
                        mdi-lock-reset
                    </v-icon>
                    {{labels.OD_RST_PWD}}
                </v-btn>

                <!-- Delete Selected -->
                <!-- Will be enable if there is at least 1 item been selected in table -->
                <v-btn
                    v-if="uAccess.ORG_DELETEUSER"
                    plain
                    text
                    elevation="0"
                    color="info"
                    class="nocap"
                    :disabled="!selectedUsers[0]"
                    @click="toggleConfirm('deleteSelectedUsers')"
                >
                    <v-icon color="info">
                        mdi-trash-can-outline
                    </v-icon>
                    {{labels.OD_DEL_SELTED}}
                </v-btn>

                <!-- Show Deleted Users -->
                <v-btn
                    plain
                    elevation="0"
                    color="tertiary1"
                    class="nocap"
                    text
                    @click="manageUsers.toggleShowDeleted"
                >
                    <v-icon>
                        mdi-delete-clock
                    </v-icon>
                    {{labels.OD_SH_DEL_USR}}
                </v-btn>
            </v-row>
        </div>

        <!-- Group of deleted users operation buttons group -->
        <div v-show="showDeletedUsers">
            <v-row class="d-flex justify-space-between">
                <!-- Reactivate Selected Users -->
                <v-btn
                    elevation="0"
                    color="tertiary2"
                    class="nocap"
                    :dark="!!selectedDelUsers[0]"
                    :disabled="!selectedDelUsers[0]"
                    @click="manageUsers.reactivateDeleted"
                >
                    <v-icon>
                        mdi-account-reactivate
                    </v-icon>
                    {{labels.OD_REACTIVE}}
                </v-btn>

                <!-- Show Active Users -->
                <v-btn
                    plain
                    elevation="0"
                    color="tertiary2"
                    class="nocap"
                    text
                    @click="manageUsers.toggleShowDeleted"
                >
                    <v-icon>
                        mdi-account-multiple-check
                    </v-icon>
                    {{labels.OD_ACT_USR}}
                </v-btn>
            </v-row>
        </div>

        <v-row class="mt-2 mb-6"><v-divider></v-divider></v-row>

        <!-- ------------------ -->
        <!-- Manage users table -->
        <!-- ------------------ -->
        
        <!-- <v-data-table
            :mobile-breakpoint="0"
            v-show="!showDeletedUsers"
            v-model="selectedUsers"
            :headers="headers"
            :items="organisation.users"
            item-key="user_id"
            show-select
            class="elevation-1 s-table-beige s-table"
            @click:row="editUser"
            style="cursor:pointer"
            :ripple="false"
        > -->
        <v-data-table
            :mobile-breakpoint="0"
            v-show="!showDeletedUsers"
            v-model="selectedUsers"
            :headers="headers"
            :items="organisation.users"
            item-key="user_id"
            show-select
            class="elevation-1 s-table-beige s-table b-table"
            @click:row="editUser"
            style="cursor:pointer">

<!-- Edit Icon for user management -->
        <template v-slot:[`item.edit_user`]="{}">
                <div class="d-flex justify-left">
                    <v-icon class="s-icon__edit-user">
                        mdi-account-edit
                    </v-icon>
                </div>
        </template>
<!-- Disable root user checkbox -->
        <template v-slot:[`item.data-table-select`]="{ item, isSelected, select}">
            <v-simple-checkbox
                :value="isSelected"
                :ripple="false"
                :disabled="item.root_user=='yes'"
                :readonly="item.root_user=='yes'"
                @input="select($event)"
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.user_name`]="{ item }" >    
            <div>
                {{ getchipemail(item.user_name) }}
            </div>
        </template>
        <!-- Tab(chip) styles for the Group column in ManageUsers table -->
            <template v-slot:[`item.groups`]="{ item }" >    
                <div>
                    <v-chip
                        class="mr-2 my-1"
                        color="secondary darken-2"
                        dark
                        small
                        v-for="(group, index) in item.groups"
                        :key="index"
                        >{{ group.value }}</v-chip
                    >
                </div>
            </template>
            <template v-slot:top> </template>
        </v-data-table>
        <!-- -* ABOVE *- Manage users table -->

        <!-- ------------------- -->
        <!-- Deleted Users Table -->
        <!-- ------------------- -->
        <v-data-table
            v-show="showDeletedUsers"
            :mobile-breakpoint="0"
            v-model="selectedDelUsers"
            :headers="delUsersTableHeader"
            :items="organisation.deactiveUsers"
            item-key="user_id"
            show-select
            class="elevation-1 s-table-beige s-table"
            style="cursor:pointer"
        >   
      
            <template v-slot:[`item.edit_user`]="{}">
                <div class="d-flex justify-center">
                    <v-icon class="s-icon__edit-user">
                        mdi-account-edit
                    </v-icon>
                </div>
            </template>
            <template v-slot:[`item.user_name`]="{ item }" >    
                <div>
                    {{ getchipemail(item.user_name) }}
                </div>
            </template>

            <!-- Tab(chip) styles for the Group column in ManageUsers table -->
            <template v-slot:[`item.groups`]="{ item }">
                <div>
                    <v-chip
                        class="ma-1"
                        color="primarytextcolor darken-2"
                        dark
                        small
                        v-for="(group, index) in item.groups"
                        :key="index"
                        >{{ group }}</v-chip
                    >
                </div>
            </template>
            <template v-slot:top> </template>
        </v-data-table>
        <!-- -* ABOVE *- Deleted Users Table -->

        <!-- ------------------- -->
        <!-- Editing User Dialog -->
        <!-- ------------------- -->
        <!-- <v-dialog
            persistent
            v-model="editingUser"
            content-class="s-css-reset white"
            max-width="960"

        >
            <div>
                <v-container>
                    <v-row class="mt-4 d-flex justify-center align-center">
                        <v-col></v-col>
                        <v-col><h3>{{labels.OD_EDIT}}</h3></v-col>
                        <v-icon
                            large
                            @click="closeEdit()"
                            style="display:inline;position:relative"
                        >
                            mdi-close
                        </v-icon>
                    </v-row>

                    <v-row class="mt-4"><v-divider></v-divider></v-row>
                </v-container>

                <ManageUsersEditUser v-on:test="getDetail" v-if="editingUser"/>
            </div>
        </v-dialog> -->
        <!-- -* ABOVE *- Editing User Dialog -->

        <!-- Reset Password Dialog -->
        <!-- <v-dialog v-model="alertResetPassword"> </v-dialog> -->
        <!-- <v-dialog v-model="alertDeleteSelectedUsers"> </v-dialog> -->

        <!-- Popup Windows -->
        <Dialog
            :dialog-title="labels.ADD_USER_TITLE"
            dialog-name="orgManageUsersAddUser"
            :buttons="false"
            :close-button="false"
            confirm-btn-text="Create"
        >
            <AddUser v-if="showbox" />
        </Dialog>

        <!--RESET USERS PASSWORDS START-->
        <ConfirmBox
            box-name="resetUsersPassword"
            :on-confirm-value="selectedUsers"
            :box-title="labels.OD_CON_RST_S_PSD"
            :box-content="labels.OD_CON_RST_S_PSD_SMS"
            box-class="warning-dialog"
            :confirm-btn-text="labels.OD_RST_PWD"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-lock-reset"
        >
        </ConfirmBox>
        <!--RESET USERS PASSWORDS END-->

        <!--DELETE USERS  START-->
        <ConfirmBox
            box-name="deleteSelectedUsers"
            :on-confirm-value="selectedUsersId"
            :box-title="labels.DEL_CONF_SMS"
            :box-content="`${labels.OD_USERS} : <b>${selectedUsersNames.toString().replace(/\,/g, ', ')}</b> ${labels.OD_WILLBE} </br> ${labels.OD_YOUCAN}`"
            box-class="error-dialog"
            :confirm-btn-text="labels.OD_DEL"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-delete"
        >
        </ConfirmBox>
        
    <!--DELETE USERS  START-->

    <!--REACTIVATE DEL USERS  START-->
        <ConfirmBox
            box-name="reactivateSelectedUsers"
            :on-confirm-value="selectedDelUsers"
            :box-title="labels.OD_REACT_USERS_CON_TITLE"
            :box-content="labels.OD_REACT_USERS_CON"
            box-class="warning-dialog"
            :confirm-btn-text="labels.OD_RE_ACT"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-lock-reset"
        >
        </ConfirmBox>
        <!--REACTIVATE DEL USERS END-->

        <!-- <Loader ref="loader" /> -->
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    ref
} from '@vue/composition-api';
// Components
import ManageUsersEditUser from './ManageUsersEditUser';
import Dialog from '../ui/Dialog';
import AddUser from './AddUser';
import Loader from '../ui/Loader';
import ConfirmBox from "../ui/ConfirmBox";

// Services
import { organisationService } from '../../services/organisationService';

export default defineComponent({
    components: {
        ManageUsersEditUser,
        AddUser,
        Dialog,
        Loader,
        ConfirmBox
    },
    setup(_, ctx) {
            const session=ctx.root.$session,
            u_session=session.get("user_session").session,
            uAccess=u_session.uAccess;
            const onprem=ref(u_session.onprem);
        // Import individual variables and functions from organisationServices.js
        const {
            // usersData,
            organisation,
            getCurrentEditingUser,
            dialogs,
            toggleDialog,
            confirmBoxs,
            toggleConfirm,
            getuserList,
            getdeactiveuserList,
        } = organisationService();

        const showbox=ref(false);

        getuserList();
        getdeactiveuserList();
        // Set up table content and table headers
        // Use ref() to make variable reactive
        let selectedUsers = ref([]),
            // TODO: turn to false when finishend UI editing
            editingUser = ref(false),
            alertResetPassword = ref(false),
            labels=ref({}),
            alertDeleteSelectedUsers = ref(false);
        
        // Table headers
        const headers = ref([]);
        const delUsersTableHeader = ref([]);
        //**GET LABELS */
        setTimeout(() => {
            labels.value=organisation.labels;
             headers.value=[{
                text: labels.value.USER_NAME,
                align: 'center',
                value: 'user_name'
            },
            { text: labels.value.USER_GROUP, align: 'center', value: 'groups' },
            { text: labels.value.USER_ACT, align: 'center', value: 'last_activity' },
            { text: labels.value.USER_CREATE, align: 'center', value: 'created_by' },
            { text: labels.value.USER_C_DATE, align: 'center', value: 'date_created' },
            { text: labels.value.OD_EDIT, align: 'center', value: 'edit_user' }];

            delUsersTableHeader.value=[ {
                text: labels.value.USER_NAME,
                align: 'start',
                value: 'user_name'
                },
                { text: labels.value.USER_CREATE, value: 'created_by' },
                { text: labels.value.DEL_ON, value: 'date_updated' }
            ];

        }, 2000);
        
        let selectedUsersNames = computed(() => {
            let names = [];
            selectedUsers.value.forEach((user) => {
                //alert("user : "+JSON.stringify(user));
                if(user.root_user!='yes'){
                    names.push(user.user_name);
                }
            });
            return names;
        });

        let selectedUsersId = computed(() => {
            let userid = [];
            selectedUsers.value.forEach((user) => {
                //alert("user : "+JSON.stringify(user));
                if(user.root_user!='yes'){
                    userid.push(user.user_id);
                }
            });
            return userid;
        });

        const text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';

        const editUser = (userDetails) => {
            // console.log('editing user:', JSON.stringify(userDetails));
            // calling editUser function in organisation service
            getCurrentEditingUser(userDetails);

            // toggle dialog pop up window
            editingUser.value = true;
        };

        const closeEdit = () => {
            editingUser.value = false;
        };

        const reload = () => {
            showbox.value = false;
            setTimeout(() => {showbox.value = true;},10);
        };
        

        // Object to store all functions for manage users tab
        const  showDeletedUsers = ref(false),
            selectedDelUsers = ref([]),
            {
                DUMMY_deletedUsers,
            } = organisationService();

        const manageUsers = {
            toggleShowDeleted: () => {
                showDeletedUsers.value = !showDeletedUsers.value;
                console.log('*** Show Deleted Users');
            },
            reactivateDeleted: () => {
                // Calling organisationServices function make the API call to reactivate deleted users

                toggleConfirm("reactivateSelectedUsers");
                //reactivateDeletedUsers(selectedDelUsers.value);
            }
        };

        const loader = ref(null);
        onMounted(() => {
            loader.value.start();
            setTimeout(() => {
                loader.value.stop();
            }, 1000);
            console.log('Test Mounted');
        });

        const showAddUser=ref(false);

        const getDetail = () => {
            alert("dd");
        }
        const getchipemail=(emailvalue) =>{
            var emailarray =emailvalue.split("@");
            var formatemail=emailvalue;
            if(emailarray[0].length>20){
                formatemail=emailarray[0].slice(0,20)+"...@"+emailarray[1];
            }
            return formatemail;
        };

        return {
            loader,
            selectedUsers,
            headers,
            // usersData,
            editUser,
            editingUser,
            closeEdit,
            text,
            alertResetPassword,
            alertDeleteSelectedUsers,
            dialogs,
            toggleDialog,
            confirmBoxs,
            toggleConfirm,
            showAddUser,
            selectedUsersNames,
            selectedUsersId,
            organisation,
            getuserList,
            getdeactiveuserList,
            // Manage Deleted users
            showDeletedUsers,
            delUsersTableHeader,
            selectedDelUsers,
            DUMMY_deletedUsers,
            manageUsers,
            labels,
            u_session,
            uAccess,
            onprem,
            getDetail,
            showbox,
            reload,
            getchipemail
        };
    }
});
</script>

<style lang="scss" scoped>
.s-icon__edit-user {
    color:var(--v-tertiary1-base);
}
.s-icon__edit-user:hover {
    color:var(--v-tertiary1-base);
}

// Disable background highlight after user click the icon button
.v-icon.v-icon::after {
    background-color: rgba(0, 0, 0, 0);
}

.b-table {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
</style>
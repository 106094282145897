<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop" />
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0">
              <a>
                <v-btn text class="nocap px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.BK }}
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.FL_CD_L }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="clearData()"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Flexy Error
              </v-btn> -->
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between
            "
          >
          <div> 
            <v-chip outlined
              @click="makeSelectedEmpty"
              class="ma-2 white"
            v-if="selectedCode.length"
            >
              <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedCode.length }} selected
            </v-chip>
          </div>
            <div>
              <v-btn
                text
                class="tertiary2--text nocap py-5"
                @click.stop="clearData()"
              >
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-format-list-checks
                </v-icon>
                {{ labels.AD_FL_CD_L }}
              </v-btn>
              <v-btn
                text
                @click.stop="getItem"
                :disabled="selectedCode.length != 1"
                class="tertiary1--text nocap py-5"
              >
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-file-edit-outline</v-icon
                >
                {{ labels.EDI }}
              </v-btn>
              <v-btn
                text
                @click.stop="deletecodelistCode = true"
                :disabled="!selectedCode.length"
                class="error--text nocap py-5"
              >
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-trash-can
                </v-icon>
                {{ labels.DEL }}
              </v-btn>
            </div>
          </div>
          <div>
            <v-data-table
              :mobile-breakpoint="0"
              v-model="selectedCode"
              :headers="headers"
              :items="flexyCodeData"
              item-key="id"
              show-select
              @click:row="navTovaluelist"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addflexycodeDialog"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.NW_FL_CD_L }}</h3>
                    </div>
                    <div class="closeicon-right">
                      <v-icon
                        @click="addflexycodeDialog = false"
                        class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px" class="px-6">
                    <div>
                      <v-col cols="12" class="basicInfo-tlt">
                        <h3>Basic info</h3>
                      </v-col>

                      <div>
                        <addflexycodelist
                          :newCode="newCode"
                          :isValidForm="isValidForm"
                          :langItems="langItems"
                          :labels="labels"
                        />
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <div class="my-4 px-md-10">
                      <v-alert
                        class="text-left"
                        text
                        :type="response_status"
                        v-if="response_msg.length"
                      >
                        {{ response_msg }}
                      </v-alert>
                    </div>
                  <v-card-actions class="addflexy-vcol justify-center">
                    <v-btn
                      class="nocap mt-1"
                      large
                      text
                      @click="addflexycodeDialog = false"
                    >
                      {{ labels.CAN }}
                    </v-btn>
                    <v-btn
                      class="nocap mx-5 mt-1"
                      large
                      color="primary"
                      @click="save()"
                    >
                      {{ labels.SAV }}
                    </v-btn>
                    <v-checkbox v-if="isSuperAdmin" class="mt-0 ml-4"
                    v-model="newCode.effectAllOwners"
                    :label="`Effect All Owners`"
                  ></v-checkbox>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successCode"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{ response_message }}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successCode = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletecodelistCode"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected flexy code list?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletecodelistCode = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletecodelistCodes()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ Flexy Edit Pages +++++--------->
          <v-dialog
            v-model="flexycodelistEditdialog"
            width="1000"
            height="400"
            scrollable
            persistent
          >
            <flexycodelistEdit
              @closeCodeEdit="closeEdit"
              :newCode="newCode"
              :langItems="langItems"
              @save="save"
              :response_msg="response_msg"
              :response_status="response_status"
              :labels="labels"
            />
          </v-dialog>
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader" />
  </div>
</template> 
<script>
import addflexycodelist from "./flexy/addflexycodelist.vue";
import flexycodelistEdit from "./flexycodelistEdit.vue";
import API_Util from "../services/util/API_Util.js";
import loaderimg from "./loaderimg.vue";
import headline from "./headline.vue";

export default {
  components: {
    addflexycodelist,
    flexycodelistEdit,
    loaderimg,
    headline
  },
  name: "codelist",
  data: () => ({
    response_message: "",
    ownerList: [],
    flexycodelistEditdialog: false,
    successCode: false,
    flexycodedetail: false,
    owner: "",
    apiKey: "",
    addflexycodeDialog: false,
    enableDelete: false,
    deletecodelistCode: false,
    selectedOwner: "",
    headers: [
      {
        text: "LIST CODE",
        align: "start",
        sortable: true,
        value: "code",
      },
      { text: "LIST NAME", value: "title" },
      { text: "	LIST DESCRIPTION", value: "desc" },
    ],
    isValidForm: true,
    flexyCodeData: [],
    langItems: [],
    selectedCode: [],
    newCode: {
      id: 0,
      code: "",
      title: "",
      desc: "",
      orgId: localStorage.getItem("orgId"),
      ownerId: localStorage.getItem("owner"),
      dateUpdated: "",
      updatedBy: 2,
      subList: "yes",
      effectAllOwners: false,
    },
    checkbox: false,
    response_msg: "",
    response_status: "",
    loading: false,
    search: "",
    codeList: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Localisation - Flexy Codes",
        disabled: true,
        href: "",
      },
    ],
    loader: true,
    ownerType: "",
    labels: {},
    language: "EN",
    ownerDrop: "",
    ownerDesc: "",
    isSuperAdmin: localStorage.getItem('owner') == 1,
  }),

  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = localStorage.getItem("ownerType");
    localStorage.setItem("orgId", sessionObj.session.org);
    this.selectedOwner = localStorage.getItem("ownerName");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    localStorage.setItem('orgId', this.owner);
    this.orgId = localStorage.getItem("orgId");
    this.items[1].text =
      "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getFlexi();
    this.getLangList();
    this.getOrgList();
    this.getflexycodeList();
  },

  computed: {},

  watch: {
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  methods: {
    clearData() {
      this.newCode = {
        id: 0,
        code: "",
        title: "",
        desc: "",
        orgId: 1,
        ownerId: localStorage.getItem("owner"),
        dateUpdated: "",
        updatedBy: 2,
        subList: "yes",
        effectAllOwners: false,
      };
      this.addflexycodeDialog = true;
      this.isValidForm = true;
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem("ownerName", obj.ownerName);
      localStorage.setItem("orgId", obj.id);
      localStorage.setItem('ownerType', obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text =
        "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.isSuperAdmin = localStorage.getItem('owner') == 1;
      this.getLangList();
    },
    getOrgList() {
        var empt = {}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },

    allOwners() {
      this.$router.push("/ownerlisting");
    },
    closeEdit(status) {
      this.flexycodelistEditdialog = status;
      this.langItems.forEach((row) => {
        row.disable = false;
      });
    },
    getItem() {
      this.newCode = this.selectedCode[0];
      console.log(11, this.newCode);
      this.flexycodelistEditdialog = true;
    },
    navTovaluelist(obj) {
      localStorage.setItem("codeIdd", obj.id);
      this.$router.push("/valuelist");
    },
    getLangList() {
      const headers = { "x-api-key": this.apiKey };
      var empt = {};
      API_Util.URLS.axios
        .post(API_Util.URLS.flexyLangValue, empt, { headers })
        .then((response) => {
          this.langItems = response.data.result;
          this.loader = false;
        });
    },
    getflexycodeList() {
      const headers = { "x-api-key": this.apiKey };
      var ids = {"orgId": localStorage.getItem("orgId"), "ownerId": localStorage.getItem("owner")};
      API_Util.URLS.axios
        .post(
          API_Util.URLS.flexycodeList, ids,
          { headers }
        )
        .then((response) => {
          this.flexyCodeData = response.data.result;
          this.loader = false;
        });
    },
    save() {
      if (this.newCode.code == "") {
        this.isValidForm = false;
      } else if (this.newCode.title == "") {
        this.isValidForm = false;
      } else {
        this.newCode.orgId = localStorage.getItem("owner");
        if (this.newCode.id > 0) {
            if (this.newCode.effectAllOwners === undefined) {
              this.newCode.effectAllOwners = false;
            }
            (this.newCode.ownerId = localStorage.getItem("owner"));
          this.newCode.dateUpdated = "";
        }
        this.newCode.updatedBy = this.$session.get("user_session").session.id;
        var self = this;
        const headers = { "x-api-key": this.apiKey };
        API_Util.URLS.axios
          .post(API_Util.URLS.flexyCodeListAdd, self.newCode, { headers })
          .then((response) => {
            var resp = response.data;
            if (resp.message == "CODE_EXIST") {
              self.response_msg = "Code Already Exists";
              self.response_status = "error";
              setTimeout(() => {
                self.response_msg = "";
              }, 2000);
            } else {
              // self.refreshData();
              if(self.newCode.id > 0){
                self.response_message = "Successfully Updated";
                self.successCode = true;
              }
              else{
                self.response_message = "Successfully created";
                self.successCode = true;
              }
              setTimeout(() => {
                self.flexycodelistEditdialog = false;
                self.addflexycodeDialog = false;
                self.clearObjectData();
                self.getflexycodeList();
                self.navTovaluelist(response.data.result);
              }, 2000);
            }
          })
          .catch((err) => console.log(err));
      }
    },
    clearObjectData() {
      this.response_msg = "";
      this.isValidForm = true;
    },
    refreshData() {
      this.newCode = {
        id: 0,
        code: "",
        title: "",
        desc: "",
        orgId: localStorage.getItem("owner"),
        ownerId: localStorage.getItem("owner"),
        dateUpdated: "",
        updatedBy: 2,
        subList: "yes",
        effectAllOwners: false,
      };
    },
    deletecodelistCodes() {
      this.loader = true;
      let toDelete = [];
      this.selectedCode.forEach((arrayItem) => {
        toDelete.push(arrayItem.id);
      });
      API_Util.URLS.axios
        .post(API_Util.URLS.deleteFlexyCodeList, toDelete, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          if(response.status == 200){
            console.log("success",response)
            toDelete = [];
            this.selectedCode = [];
            this.getflexycodeList();
            this.loader = false;
          }
        })
        .catch((err) => console.log(err));
      this.deletecodelistCode = false
    },
    makeSelectedEmpty() {
      this.selectedCode = [];
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "LANG_SET",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          this.header = [
                          {
                            text: this.labels.SCR_NM,
                            align: "start",
                            sortable: true,
                            value: "screenName",
                          },
                          { text: this.labels.SCR_CD, value: "screenCode" },
                          { text: this.labels.LBL_CD, value: "labelCode" },
                          { text: this.labels.TEXT, value: "text" },
                        ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
// .v-menu__content {
//   background-color: white;
//   top: 236px !important;
// }
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .closeicon-right .v-icon {
    position: relative;
    top: -10px;
  }
}
</style>
